import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CheckPin from './CheckPin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    listItem: {
      backgroundColor: "#60489D",
      color: "white",
      "&:hover": {
        backgroundColor: "#60489D !important",
      }
    },
    listItemText: {
      "&> span": {
        fontWeight: "bold",
        fontSize: "1.5rem",
        textTransform: "uppercase"
      }
    },
    divider: {
      marginTop: "5px",
      marginBottom: "5px"
    },
    title: {
      textAlign: "center",
    },
    titleInner: {
      textTransform: "uppercase",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "1.5rem"
    }
  });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserList = props => {
    const classes = useStyles();
    const { user } = props
    const [showVerify, setShowVerify] = useState(false)
    let verify

    if(showVerify){
      verify = <Dialog
                  open={showVerify}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={()=>setShowVerify(false)}
                  aria-labelledby="check-pin"
                  aria-describedby="check-pin-description"
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle id="check-pin" className={classes.title}><Typography variant='body1' className={classes.titleInner}>PIN NUMBER</Typography></DialogTitle>
                  <DialogContent>
                    <CheckPin empid={user.empid} />
                  </DialogContent>
                </Dialog>
    }

    return (
        <>{verify}<ListItem button onClick={()=>setShowVerify(true)} className={classes.listItem}>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Grid
                    spacing={0}
                    container 
                    alignItems="center"
                    justifyContent="center">
                    <Grid
                    item
                    style={{textAlign:"center"}}
                    xs={12}
                  >
                      {user.name}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <Divider className={classes.divider} />
            </>
    )
}

export default UserList