import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles';
import ModeContext from './ModeContext';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Home from './Home';
import Header from './Header';
import ChangeStatus from './ChangeStatus';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

function refreshAt(hours, minutes, seconds) {
  var now = new Date();
  var then = new Date();

  if(now.getHours() > hours ||
     (now.getHours() === hours && now.getMinutes() > minutes) ||
      (now.getHours() === hours && now.getMinutes() === minutes && now.getSeconds() >= seconds)) {
      then.setDate(now.getDate() + 1);
  }
  then.setHours(hours);
  then.setMinutes(minutes);
  then.setSeconds(seconds);

  var timeout = (then.getTime() - now.getTime());
  setTimeout(function() { window.location.reload(true); }, timeout);
}
const useStyles = makeStyles({
  root: {
    minWidth: "100%",
    minHeight: "100vh",
    backgroundColor: '#000',
    color: 'white'
  }
});
const App = props => {
  const classes = useStyles();
  const [mode, setMode] = useState(undefined)
  const ModeValue = useMemo(()=> ({mode, setMode}), [mode, setMode]);
  useEffect(() => {
    refreshAt(0,0,0);
  }, [])

  if(mode !== undefined){
    return(
      <QueryClientProvider client={queryClient}>
      <ModeContext.Provider value={ModeValue}>
      <Grid className={classes.root} spacing={0} container>
        <Container maxWidth="sm">
          <Header />
          <ChangeStatus />
        </Container>
      </Grid>
      </ModeContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
  }

  

  return (
    <QueryClientProvider client={queryClient}>
    <ModeContext.Provider value={ModeValue}>
      <Grid className={classes.root} spacing={0} container>
        <Container maxWidth="sm">
          <Header />
          <Home />
        </Container>
      </Grid>
      </ModeContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  
}

export default App;
