import React, { useContext } from "react";
import axios from "axios";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserList from "./UserList";
import { useQuery } from "@tanstack/react-query";
import { Grid, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import ModeContext from "./ModeContext";

let store = require("./store.json");

const useStyles = makeStyles({
  listItem: {
    backgroundColor: "#FFF",
    color: "black",
    "&:hover": {
      backgroundColor: "#999 !important",
    },
    marginTop: "20px",
  },
  listItemTop: {
    backgroundColor: "#FFF",
    color: "black",
    "&:hover": {
      backgroundColor: "#999 !important",
    },
    marginBottom: "20px",
  },
  listItemText: {
    "&> span": {
      fontWeight: "bold",
      fontSize: "1.2rem",
      textTransform: "uppercase",
    },
  },
});

const ChangeStatus = () => {
  const { setMode } = useContext(ModeContext);
  const classes = useStyles();
  const { isLoading, isError, data } = useQuery({
    queryKey: ["users"],
    queryFn: ({ signal }) => axios.post("https://api.teeam.io/get_employees_timeclock.php", { store: store.id }, { signal }),
  });

  if (isLoading) {
    return (
      <Fade in style={{ textAlign: "center" }}>
        <div>
          <CircularProgress />
        </div>
      </Fade>
    );
  }

  if (isError || data.data.employees.length === 0) {
    return (
      <Fade in style={{ textAlign: "center" }}>
        <div>An error occured loading the user list. Please try again!</div>
      </Fade>
    );
  }
  let usersList;
  usersList = data.data.employees.map((user, index) => {
    return <UserList key={index} user={user} />;
  });
  return (
    <List component="nav">
      <ListItem button style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => setMode(undefined)} className={classes.listItemTop}>
        <ListItemText
          className={classes.listItemText}
          primary={
            <Grid spacing={0} container alignItems="center" justifyContent="center">
              <Grid item style={{ textAlign: "center" }} xs={12}>
                GO BACK TO OPTIONS
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      {usersList}
      <ListItem button style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => setMode(undefined)} className={classes.listItem}>
        <ListItemText
          className={classes.listItemText}
          primary={
            <Grid spacing={0} container alignItems="center" justifyContent="center">
              <Grid item style={{ textAlign: "center" }} xs={12}>
                GO BACK TO OPTIONS
              </Grid>
            </Grid>
          }
        />
      </ListItem>
    </List>
  );
};

export default ChangeStatus;
